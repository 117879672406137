'use client'

import { datadogRum } from '@datadog/browser-rum'

if (
  process.env.NEXT_PUBLIC_ENVIRONMENT &&
  process.env.NEXT_PUBLIC_ENVIRONMENT.toUpperCase() !== 'LOCAL'
) {
  datadogRum.init({
    applicationId: '71f38325-605f-4c11-b6ff-58c46da78ead',
    clientToken: 'pub4b920b240c07783f080fb4c8c605986b',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'trackz-portal',
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
